import React from 'preact/compat';
import { Icon, AssetsContainer, Asset } from '../../elements/icons';
import { ContentContainer, Section } from '../../elements/composition';
import {
    Description, Text, Title, BreakLine,
} from '../../elements/typography';
import { width } from '../../../style/constants';

import consensus from '../../../assets/ico/RULES/ico-consensus.png';
import consensusRetina from '../../../assets/ico/RULES/ico-consensus@2x.png';
import dao from '../../../assets/ico/RULES/ico-DAO.png';
import daoRetina from '../../../assets/ico/RULES/ico-DAO@2x.png';
import diversity from '../../../assets/ico/RULES/ico-diversity-view.png';
import diversityRetina from '../../../assets/ico/RULES/ico-diversity-view@2x.png';
import respect from '../../../assets/ico/RULES/ico-respect.png';
import respectRetina from '../../../assets/ico/RULES/ico-respect@2x.png';
import transparency from '../../../assets/ico/RULES/ico-transparency-process.png';
import transparencyRetina from '../../../assets/ico/RULES/ico-transparency-process@2x.png';


const EngagementSection = (
) => (
    <Section dark id="engagement">
        <ContentContainer maxWidth={width.maxIconSection}>
            <Title center>values</Title>
            <Description center>Of Engagement</Description>
            <AssetsContainer>
                <Asset>
                    <Icon src={transparency} srcSet={`${transparencyRetina} 2x`} alt="Transparency icon" />
                    <Text>Transparency<BreakLine />of decision process</Text>
                </Asset>
                <Asset>
                    <Icon src={diversity} srcSet={`${diversityRetina} 2x`} alt="Diversity icon" />
                    <Text>Diversity<BreakLine />of views</Text>
                </Asset>
                <Asset>
                    <Icon src={respect} srcSet={`${respectRetina} 2x`} alt="Respect icon" />
                    <Text>Respect<BreakLine />of all voices</Text>
                </Asset>
                <Asset>
                    <Icon src={consensus} srcSet={`${consensusRetina} 2x`} alt="Consensus icon" />
                    <Text>Consensus<BreakLine />building</Text>
                </Asset>
                <Asset>
                    <Icon src={dao} srcSet={`${daoRetina} 2x`} alt="Democracy icon" />
                    <Text>Democracy<BreakLine />DAO</Text>
                </Asset>
            </AssetsContainer>
        </ContentContainer>
    </Section>
);

export default EngagementSection;
