import React from 'preact/compat';
import { ContentContainer, Section } from '../../elements/composition';
import { AssetsContainer, Image, LogoAsset } from '../../elements/icons';
import { Title, Link } from '../../elements/typography';

import annaTheiss from '../../../assets/partnersLogo/logo-anna-theiss.png';
import annaTheissRetina from '../../../assets/partnersLogo/logo-anna-theiss@2x.png';
import arius from '../../../assets/partnersLogo/logo-arius.png';
import ariusRetina from '../../../assets/partnersLogo/logo-arius@2x.png';
import artauthentify from '../../../assets/partnersLogo/logo-art-authentify.png';
import artauthentifyRetina from '../../../assets/partnersLogo/logo-art-authentify@2x.png';
import artcryption from '../../../assets/partnersLogo/logo-artcryption.png';
import artcryptionRetina from '../../../assets/partnersLogo/logo-artcryption@2x.png';
import artRatio from '../../../assets/partnersLogo/logo-art-ratio.png';
import artRatioRetina from '../../../assets/partnersLogo/logo-art-ratio@2x.png';
import arteia from '../../../assets/partnersLogo/logo-arteia.png';
import arteiaRetina from '../../../assets/partnersLogo/logo-arteia@2x.png';
import articheck from '../../../assets/partnersLogo/logo-articheck.png';
import articheckRetina from '../../../assets/partnersLogo/logo-articheck@2x.png';
import artlawservices from '../../../assets/partnersLogo/logo-artlawservices.png';
import artlawservicesRetina from '../../../assets/partnersLogo/logo-artlawservices@2x.png';
import artmavens from '../../../assets/partnersLogo/logo-artmavens.png';
import artmavensRetina from '../../../assets/partnersLogo/logo-artmavens@2x.png';
import artmoney from '../../../assets/partnersLogo/logo-artmoney.png';
import artmoneyRetina from '../../../assets/partnersLogo/logo-artmoney@2x.png';
import artmyn from '../../../assets/partnersLogo/logo-artmyn.png';
import artmynRetina from '../../../assets/partnersLogo/logo-artmyn@2x.png';
import artory from '../../../assets/partnersLogo/logo-artory.png';
import artoryRetina from '../../../assets/partnersLogo/logo-artory@2x.png';
import artrax from '../../../assets/partnersLogo/logo-artrax.png';
import artraxRetina from '../../../assets/partnersLogo/logo-artrax@2x.png';
import artsci from '../../../assets/partnersLogo/logo-art-sci.png';
import artsciRetina from '../../../assets/partnersLogo/logo-art-sci@2x.png';
import axaxl from '../../../assets/partnersLogo/logo-axaxl.png';
import axaxlRetina from '../../../assets/partnersLogo/logo-axaxl@2x.png';
import bsbeducation from '../../../assets/partnersLogo/logo-bsbeducation.png';
import bsbeducationRetina from '../../../assets/partnersLogo/logo-bsbeducation@2x.png';
import blondieauRobertGary from '../../../assets/partnersLogo/logo-blondieau-robert-gary.png';
import blondieauRobertGaryRetina from '../../../assets/partnersLogo/logo-blondieau-robert-gary@2x.png';
import ccstudiocic from '../../../assets/partnersLogo/logo-ccstudiocic.png';
import ccstudiocicRetina from '../../../assets/partnersLogo/logo-ccstudiocic@2x.png';
import chiarabadinella from '../../../assets/partnersLogo/logo-chiara-badinella.png';
import chiarabadinellaRetina from '../../../assets/partnersLogo/logo-chiara-badinella@2x.png';
import chalesbelle from '../../../assets/partnersLogo/logo-chalesbelle.png';
import chalesbelleRetina from '../../../assets/partnersLogo/logo-chalesbelle@2x.png';
import collectoriq from '../../../assets/partnersLogo/logo-collectoriq.png';
import collectoriqRetina from '../../../assets/partnersLogo/logo-collectoriq@2x.png';
import dataArt from '../../../assets/partnersLogo/logo-data-art.png';
import dataArtRetina from '../../../assets/partnersLogo/logo-data-art@2x.png';
import entCounsel from '../../../assets/partnersLogo/logo-ent-counsel.png';
import entCounselRetina from '../../../assets/partnersLogo/logo-ent-counsel@2x.png';
import everledger from '../../../assets/partnersLogo/logo-everledger.png';
import everledgerRetina from '../../../assets/partnersLogo/logo-everledger@2x.png';
import fionalogharn from '../../../assets/partnersLogo/logo-pacegallery.png';
import fionalogharnRetina from '../../../assets/partnersLogo/logo-pacegallery@2x.png';
import furtherfield from '../../../assets/partnersLogo/logo-furtherfield.png';
import furtherfieldRetina from '../../../assets/partnersLogo/logo-furtherfield@2x.png';
import galleryTool from '../../../assets/partnersLogo/logo-gallery-tool.png';
import galleryToolRetina from '../../../assets/partnersLogo/logo-gallery-tool@2x.png';
import georgBak from '../../../assets/partnersLogo/logo-georg-bak.png';
import georgBakRetina from '../../../assets/partnersLogo/logo-georg-bak@2x.png';
import guida from '../../../assets/partnersLogo/logo-guida.png';
import guidaRetina from '../../../assets/partnersLogo/logo-guida@2x.png';
import immediacylabs from '../../../assets/partnersLogo/logo-immediacylabs.png';
import immediacylabsRetina from '../../../assets/partnersLogo/logo-immediacylabs@2x.png';
import jamie from '../../../assets/partnersLogo/logo-jamie.png';
import jamieRetina from '../../../assets/partnersLogo/logo-jamie@2x.png';
import jinchul from '../../../assets/partnersLogo/logo-jinchul.png';
import jinchulRetina from '../../../assets/partnersLogo/logo-jinchul@2x.png';
import karolinablasiak from '../../../assets/partnersLogo/logo-karolinablasiak.png';
import karolinablasiakRetina from '../../../assets/partnersLogo/logo-karolinablasiak@2x.png';
import kerry from '../../../assets/partnersLogo/logo-kerry.png';
import kerryRetina from '../../../assets/partnersLogo/logo-kerry@2x.png';
import banraf from '../../../assets/partnersLogo/logo-banraf.png';
import banrafRetina from '../../../assets/partnersLogo/logo-banraf@2x.png';
import marianne from '../../../assets/partnersLogo/logo-marianne.png';
import marianneRetina from '../../../assets/partnersLogo/logo-marianne@2x.png';
import matis from '../../../assets/partnersLogo/logo-matis.png';
import matisRetina from '../../../assets/partnersLogo/logo-matis@2x.png';
import mocda from '../../../assets/partnersLogo/logo-modac.png';
import mocdaRetina from '../../../assets/partnersLogo/logo-modac@2x.png';
import noemie from '../../../assets/partnersLogo/logo-noemie.png';
import noemieRetina from '../../../assets/partnersLogo/logo-noemie@2x.png';
import piet from '../../../assets/partnersLogo/logo-piet.png';
import pietRetina from '../../../assets/partnersLogo/logo-piet@2x.png';
import philippaduncan from '../../../assets/partnersLogo/logo-philippaduncan.png';
import philippaduncanRetina from '../../../assets/partnersLogo/logo-philippaduncan@2x.png';
import pixura from '../../../assets/partnersLogo/logo-pixura.png';
import pixuraRetina from '../../../assets/partnersLogo/logo-pixura@2x.png';
import sarah from '../../../assets/partnersLogo/logo-sarah.png';
import sarahRetina from '../../../assets/partnersLogo/logo-sarah@2x.png';
import superrare from '../../../assets/partnersLogo/logo-superrare.png';
import superrareRetina from '../../../assets/partnersLogo/logo-superrare@2x.png';
import serenacozzi from '../../../assets/partnersLogo/logo-serenacozzi.png';
import serenacozziRetina from '../../../assets/partnersLogo/logo-serenacozzi@2x.png';
import speakart from '../../../assets/partnersLogo/logo-speakart.png';
import speakartRetina from '../../../assets/partnersLogo/logo-speakart@2x.png';
import stimleradvantage from '../../../assets/partnersLogo/logo-stimleradvantage.png';
import stimleradvantageRetina from '../../../assets/partnersLogo/logo-stimleradvantage@2x.png';
import stendhal from '../../../assets/partnersLogo/logo-stendhal.png';
import stendhalRetina from '../../../assets/partnersLogo/logo-stendhal@2x.png';
import tesseracademy from '../../../assets/partnersLogo/logo-tesseracademy.png';
import tesseracademyRetina from '../../../assets/partnersLogo/logo-tesseracademy@2x.png';
import thecorneliusfoundation from '../../../assets/partnersLogo/logo-thecorneliusfoundation.png';
import thecorneliusfoundationRetina from '../../../assets/partnersLogo/logo-thecorneliusfoundation@2x.png';
import tmmsac from '../../../assets/partnersLogo/logo-tmmsac.png';
import tmmsacRetina from '../../../assets/partnersLogo/logo-tmmsac@2x.png';
import uncopied from '../../../assets/partnersLogo/logo-uncopied.png';
import uncopiedRetina from '../../../assets/partnersLogo/logo-uncopied@2x.png';
import vastari from '../../../assets/partnersLogo/logo-vastari.png';
import vastariRetina from '../../../assets/partnersLogo/logo-vastari@2x.png';
import ngurumi from '../../../assets/partnersLogo/logo-ngurumi.png';
import ngurumiRetina from '../../../assets/partnersLogo/logo-ngurumi@2x.png';
import editions from '../../../assets/partnersLogo/logo-editions-sources.png';
import editionsRetina from '../../../assets/partnersLogo/logo-editions-sources@2x.png';
import crownfineart from '../../../assets/partnersLogo/logo-crownfineart.png';
import crownfineartRetina from '../../../assets/partnersLogo/logo-crownfineart@2x.png';
import crozier from '../../../assets/partnersLogo/logo-crozier.png';
import crozierRetina from '../../../assets/partnersLogo/logo-crozier@2x.png';
import artrights from '../../../assets/partnersLogo/logo-artrights.png';
import artrightsRetina from '../../../assets/partnersLogo/logo-artrights@2x.png';
import immersivekind from '../../../assets/partnersLogo/logo-immersivekind.png';
import immersivekindRetina from '../../../assets/partnersLogo/logo-immersivekind@2x.png';
import witart from '../../../assets/partnersLogo/logo-witart.png';
import witartRetina from '../../../assets/partnersLogo/logo-witart@2x.png';
import zien from '../../../assets/partnersLogo/logo-zien.png';
import zienRetina from '../../../assets/partnersLogo/logo-zien@2x.png';

const MembersSection = (

) => (
    <Section id="members">
	    <ContentContainer>
		    <Title center>Members</Title>
		    <AssetsContainer>
			    <LogoAsset>
				    <Link href="https://www.linkedin.com/in/anna-theiss-174580151/" target="_blank">
					    <Image src={annaTheiss} srcSet={`${annaTheissRetina} 2x`} alt="Anna Theiss Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.ariustechnology.com" target="_blank">
					    <Image src={arius} srcSet={`${ariusRetina} 2x`} alt="Arius Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://authentifyart.com" target="_blank">
					    <Image src={artauthentify} srcSet={`${artauthentifyRetina} 2x`} alt="Art Authentify Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://artcryption.com" target="_blank">
					    <Image src={artcryption} srcSet={`${artcryptionRetina} 2x`} alt="Artcryption Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.artratio.co.uk" target="_blank">
					    <Image src={artRatio} srcSet={`${artRatioRetina} 2x`} alt="Art Ratio Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.arteia.com/en/" target="_blank">
					    <Image src={arteia} srcSet={`${arteiaRetina} 2x`} alt="Arteia Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.articheck.com/" target="_blank">
					    <Image src={articheck} srcSet={`${articheckRetina} 2x`} alt="Articheck Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.artlawservices.com" target="_blank">
					    <Image src={artlawservices} srcSet={`${artlawservicesRetina} 2x`} alt="Artlawservices Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.artmavens.io" target="_blank">
					    <Image src={artmavens} srcSet={`${artmavensRetina} 2x`} alt="Artmavens Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.artmoney.com/au" target="_blank">
					    <Image src={artmoney} srcSet={`${artmoneyRetina} 2x`} alt="Artmoney Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.artmyn.com" target="_blank">
					    <Image src={artmyn} srcSet={`${artmynRetina} 2x`} alt="Artmyn Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.artory.com/" target="_blank">
					    <Image src={artory} srcSet={`${artoryRetina} 2x`} alt="Artory Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://artracx.com" target="_blank">
					    <Image src={artrax} srcSet={`${artraxRetina} 2x`} alt="Artrax Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.artrights.me/en/" target="_blank">
					    <Image src={artrights} srcSet={`${artrightsRetina} 2x`} alt="Artrights Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.art-sci.co.uk" target="_blank">
					    <Image src={artsci} srcSet={`${artsciRetina} 2x`} alt="Art-sci Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://axaxl.com" target="_blank">
					    <Image src={axaxl} srcSet={`${axaxlRetina} 2x`} alt="AXA XL Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.bsb-education.com/professeurs-recherche/la-recherche-a-bsb/art-cultural-management/?lang=en" target="_blank">
					    <Image src={bsbeducation} srcSet={`${bsbeducationRetina} 2x`} alt="BSB Education Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.linkedin.com/in/jonathan-jewell/" target="_blank">
					    <Image src={ccstudiocic} srcSet={`${ccstudiocicRetina} 2x`} alt="CC-STUDIO CIC Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.charlesbelle.com/" target="_blank">
					    <Image src={chalesbelle} srcSet={`${chalesbelleRetina} 2x`} alt="Charles Belle Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.chiarabadinella.com" target="_blank">
					    <Image src={chiarabadinella} srcSet={`${chiarabadinellaRetina} 2x`} alt="Chiara Badinella Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://collectoriq.com" target="_blank">
					    <Image src={collectoriq} srcSet={`${collectoriqRetina} 2x`} alt="Collector IQ Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.crownworldwide.com/" target="_blank">
					    <Image src={crownfineart} srcSet={`${crownfineartRetina} 2x`} alt="Crown fine art Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.crozierfinearts.com/" target="_blank">
					    <Image src={crozier} srcSet={`${crozierRetina} 2x`} alt="Crozier Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.editionssources.com/" target="_blank">
					    <Image src={editions} srcSet={`${editionsRetina} 2x`} alt="Editions Sources Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.dataart.com" target="_blank">
					    <Image src={dataArt} srcSet={`${dataArtRetina} 2x`} alt="Data Art Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.entcounsel.com" target="_blank">
					    <Image src={entCounsel} srcSet={`${entCounselRetina} 2x`} alt="ENT COUNSEL Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.everledger.io/" target="_blank">
					    <Image src={everledger} srcSet={`${everledgerRetina} 2x`} alt="Everledger Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.pacegallery.com" target="_blank">
					    <Image src={fionalogharn} srcSet={`${fionalogharnRetina} 2x`} alt="Fiona Logharn Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.furtherfield.org/" target="_blank">
					    <Image src={furtherfield} srcSet={`${furtherfieldRetina} 2x`} alt="Furtherfield Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.gallerytool.com" target="_blank">
					    <Image src={galleryTool} srcSet={`${galleryToolRetina} 2x`} alt="Gallery Tool Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.linkedin.com/in/artadvisorgeorgbak/" target="_blank">
					    <Image src={georgBak} srcSet={`${georgBakRetina} 2x`} alt="Georg Bak labs Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://studiolegaleguida.com/home-ita/" target="_blank">
					    <Image src={guida} srcSet={`${guidaRetina} 2x`} alt="Guida Associates Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.linkedin.com/in/orenyomtov/" target="_blank">
					    <Image src={immediacylabs} srcSet={`${immediacylabsRetina} 2x`} alt="Immediacy labs Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="#" target="_blank">
					    <Image src={immersivekind} srcSet={`${immersivekindRetina} 2x`} alt="The immersive kind Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.linkedin.com/in/jamiemayminjie/" target="_blank">
					    <Image src={jamie} srcSet={`${jamieRetina} 2x`} alt="JAMIE-MAY MINJIE Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="http://www.crossborder-technologies.com/" target="_blank">
					    <Image src={jinchul} srcSet={`${jinchulRetina} 2x`} alt="JINCHUL KIM Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.linkedin.com/in/karolina-blasiak-38415817a/" target="_blank">
					    <Image src={karolinablasiak} srcSet={`${karolinablasiakRetina} 2x`} alt="Karolina Blasiak Logotype" opacityChange />
				    </Link>
			    </LogoAsset>

			    <LogoAsset>
				    <Link href="https://www.linkedin.com/in/kerrymclaney/" target="_blank">
					    <Image src={kerry} srcSet={`${kerryRetina} 2x`} alt="KERRY MCLANEY Logotype" opacityChange />
				    </Link>
			    </LogoAsset>

			    <LogoAsset>
				    <Link href="https://www.banraf.com" target="_blank">
					    <Image src={banraf} srcSet={`${banrafRetina} 2x`} alt="MAISON BANRAF Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.linkedin.com/in/mariannemagnin/" target="_blank">
					    <Image src={marianne} srcSet={`${marianneRetina} 2x`} alt="Marianne Magnin Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.csem.ch/page.aspx?pid=166098" target="_blank">
					    <Image src={matis} srcSet={`${matisRetina} 2x`} alt="Matis Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.mocda.org/" target="_blank">
					    <Image src={mocda} srcSet={`${mocdaRetina} 2x`} alt="Mocda Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.noemiepaya.com" target="_blank">
					    <Image src={noemie} srcSet={`${noemieRetina} 2x`} alt="NOEMIE PAYA Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.linkedin.com/in/phillippa-duncan-1b10b551/" target="_blank">
					    <Image src={philippaduncan} srcSet={`${philippaduncanRetina} 2x`} alt="Philippa Duncan Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.linkedin.com/in/pvanhaute/" target="_blank">
					    <Image src={piet} srcSet={`${pietRetina} 2x`} alt="PIET VAN HAUTE Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.pixura.io" target="_blank">
					    <Image src={pixura} srcSet={`${pixuraRetina} 2x`} alt="PIXURA Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.linkedin.com/in/sarah-lehat-45a2b526/" target="_blank">
					    <Image src={sarah} srcSet={`${sarahRetina} 2x`} alt="SARAH LEHAT Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://superrare.com/" target="_blank">
					    <Image src={superrare} srcSet={`${superrareRetina} 2x`} alt="Superrare Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.linkedin.com/in/serena-cozzi/" target="_blank">
					    <Image src={serenacozzi} srcSet={`${serenacozziRetina} 2x`} alt="Serena Cozzi Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.speakart.it" target="_blank">
					    <Image src={speakart} srcSet={`${speakartRetina} 2x`} alt="Speakart Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.stendhal.ai" target="_blank">
					    <Image src={stendhal} srcSet={`${stendhalRetina} 2x`} alt="Stendhal Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.stimleradvantage.com/" target="_blank">
					    <Image src={stimleradvantage} srcSet={`${stimleradvantageRetina} 2x`} alt="Stimler Advantage Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://tesseract.academy" target="_blank">
					    <Image src={tesseracademy} srcSet={`${tesseracademyRetina} 2x`} alt="Tesser Academy Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.linkedin.com/in/mutuma-marangu-91083a1/" target="_blank">
					    <Image src={tmmsac} srcSet={`${tmmsacRetina} 2x`} alt="TMMSAC Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://thecorneliusfoundation.org" target="_blank">
					    <Image src={thecorneliusfoundation} srcSet={`${thecorneliusfoundationRetina} 2x`} alt="The Cornelius Foundation Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://uncopied.art/" target="_blank">
					    <Image src={uncopied} srcSet={`${uncopiedRetina} 2x`} alt="Uncopied Art Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.vastari.com/" target="_blank">
					    <Image src={vastari} srcSet={`${vastariRetina} 2x`} alt="Vastari Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.linkedin.com/in/andrew-ngurumi-717a67a1/" target="_blank">
					    <Image src={ngurumi} srcSet={`${ngurumiRetina} 2x`} alt="WAITUMBI NGURUMI & CO Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://witart.fr/" target="_blank">
					    <Image src={witart} srcSet={`${witartRetina} 2x`} alt="Witart Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://zien.io" target="_blank">
					    <Image src={zien} srcSet={`${zienRetina} 2x`} alt="Zien Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
			    <LogoAsset>
				    <Link href="https://www.blondieau-avocats.com" target="_blank">
					    <Image src={blondieauRobertGary} srcSet={`${blondieauRobertGaryRetina} 2x`} alt="CABINET D'AVOCATS BLONDIEAU ROBERT-GARY Logotype" opacityChange />
				    </Link>
			    </LogoAsset>
		    </AssetsContainer>
	    </ContentContainer>
    </Section>
);
export default MembersSection;
