import React from 'preact/compat';
import { ContentContainer, Section } from '../../elements/composition';
import { Text, EmailLink, Title } from '../../elements/typography';

const ContactSection = () => (
    <Section id="contact" dark>
	    <ContentContainer>
		    <Title center>contact</Title>
		    <Text light>For press enquiries contact us here:</Text>
		    <EmailLink href="mailto:press@artidstandard.org">press@artidstandard.org</EmailLink>
		    <Text light>For general enquiries contact us here:</Text>
		    <EmailLink href="mailto:contact@artidstandard.org">contact@artidstandard.org</EmailLink>
	    </ContentContainer>
    </Section>
);

export default ContactSection;
