import React from 'preact/compat';
import styled from 'styled-components';
import { breakpoints, colors } from '../../../style/constants';
import { BreakLine } from '../../elements/typography';
import { ContentContainer } from '../../elements/composition';
import { SubscribeLink } from '../../elements/buttons';
import Picture from '../../elements/Picture';

import monalisa from '../../../assets/img/img-monalisa.jpg';
import monalisaRetina from '../../../assets/img/img-monalisa@2x.jpg';
import monalisaMobile from '../../../assets/img/img-monalisa-mobile.jpg';
import monalisaMobileRetina from '../../../assets/img/img-monalisa-mobile@2x.jpg';


const BannerContainer = styled.section`
    position: relative;
`;

const BannerMask = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${colors.imageMask};
    z-index: 100;
`;

const Content = styled.div`
    z-index: 102;
    position: relative;
    
`;

const PictureContainer = styled.div`
    min-height: 380px;
    overflow: hidden;
    
    img {
        min-height: 380px;
        min-width: 500px;
        max-width: none;
    }
`;

const Container = styled.div`
    position: absolute;
    top: 20%;
    
    @media all and (min-width: ${breakpoints.medium}){
        top: 35%;
    }
  
`;


const Inscription = styled.h1`
    color: ${colors.textLight};
`;

const BannerSection = () => (
    <BannerContainer>
        <PictureContainer>
            <Picture
                mobile={monalisaMobile}
                mobileRetina={monalisaMobileRetina}
                standard={monalisa}
                standardRetina={monalisaRetina}
                alt="The Mona Lisa painting"
            />
        </PictureContainer>
        <Container>
            <ContentContainer>
                <Content>
                    <Inscription>Building an industry wide standard<BreakLine />for artwork identification</Inscription>
                    <SubscribeLink href="#involved">Sign up</SubscribeLink>
                </Content>
            </ContentContainer>
        </Container>
        <BannerMask />
    </BannerContainer>
);

export default BannerSection;
