import React from 'preact/compat';
import styled from 'styled-components';
import { breakpoints, colors } from '../../style/constants';
import { Link } from '../elements/typography';
import { ContentContainer } from '../elements/composition';
import { opacityStyles } from '../elements/icons';

import facebook from '../../assets/ico/socialMedia/ico-facebook.png';
import facebookRetina from '../../assets/ico/socialMedia/ico-facebook@2x.png';
import instagram from '../../assets/ico/socialMedia/ico-instagram.png';
import instagramRetina from '../../assets/ico/socialMedia/ico-instagram@2x.png';
import twitter from '../../assets/ico/socialMedia/ico-twitter.png';
import twitterRetina from '../../assets/ico/socialMedia/ico-twitter@2x.png';
import linkedin from '../../assets/ico/socialMedia/ico-linkedin.png';
import linkedinRetina from '../../assets/ico/socialMedia/ico-linkedin@2x.png';


const FooterStyled = styled.footer`
	margin-bottom: 40px;
`;

const Content = styled.div`
	border-top: 2px solid ${colors.bgSemi};
	padding-top: 16px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	@media all and (max-width: ${breakpoints.small}){
		flex-direction: column;
		text-align: center;
	}
`;

const IconsContainer = styled.div`
	align-self: flex-start;
	
	@media all and (max-width: ${breakpoints.small}){
		align-self: center;
		margin-bottom: 8px;
	}
`;

const Image = styled.img`
	margin-right: 16px;
	${opacityStyles}
`;

const CopyrightContainer = styled.div`
	text-align: right;
	
	@media all and (max-width: ${breakpoints.small}){
		text-align: center;
	}
`;


const Footer = (
) => (
    <FooterStyled>
	    <ContentContainer>
		    <Content>
			    <IconsContainer>
				    <Link href="https://instagram.com/artidstandard/" target="_blank">
					    <Image src={instagram} srcSet={`${instagramRetina} 2x`} alt="Instagram icon" />
				    </Link>
				    <Link href="https://twitter.com/artIDstandard" target="_blank">
					    <Image src={twitter} srcSet={`${twitterRetina} 2x`} alt="Twitter icon" />
				    </Link>
				    <Link href="https://facebook.com/artidstandard/" target="_blank">
					    <Image src={facebook} srcSet={`${facebookRetina} 2x`} alt="Facebook icon" />
				    </Link>
				    <Link href="https://linkedin.com/company/artidstandard" target="_blank">
					    <Image src={linkedin} srcSet={`${linkedinRetina} 2x`} alt="Linkedin icon" />
				    </Link>
			    </IconsContainer>
			    <CopyrightContainer>
				    &copy; 2022 Art Identification Standard (AIS). All Rights Reserved.
			    </CopyrightContainer>
		    </Content>
	    </ContentContainer>
    </FooterStyled>
);

export default Footer;
