import styled from 'styled-components';
import PropTypes from 'prop-types';
import { breakpoints, colors } from '../../style/constants';

export const Paragraph = styled.p`
	margin-bottom: 16px;
	text-align: ${(props) => (props.center ? 'center' : 'left')};
	font-weight: ${(props) => (props.light ? '300' : '400')};
`;
Paragraph.propTypes = {
    center: PropTypes.bool,
    light: PropTypes.bool,
};
Paragraph.defaultProps = {
    center: false,
    light: false,
};

export const Description = styled.h2`
    text-align: ${(props) => (props.center ? 'center' : 'left')};
    max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : 'none')};
    margin: auto;
    margin-bottom: 40px;

`;
Description.propTypes = {
    center: PropTypes.bool,
    maxWidth: PropTypes.number,
};
Description.defaultProps = {
    center: false,
    maxWidth: 0,
};

export const Text = styled.h3`
    margin-top: 8px;
    font-weight: ${(props) => (props.light ? 400 : 600)};
    text-align: center;
`;
Text.propTypes = {
    light: PropTypes.bool,
};
Text.defaultProps = {
    light: false,
};

export const Title = styled.h4`
    color: ${colors.textSemi};
    text-align: ${(props) => (props.center ? 'center' : 'left')};
    margin-bottom: 10px;
    
    @media all and (max-width: ${breakpoints.medium}){
        margin-bottom: 4px;
    }
`;
Title.propTypes = {
    center: PropTypes.bool,
};
Title.defaultProps = {
    center: false,
};

export const BreakLine = styled.br`
    @media all and (max-width: ${breakpoints.medium}){
        content: '';
        
        &:after{
            content: ' ';
        }
    }
`;
Description.propTypes = {
    center: PropTypes.bool,
};
Description.defaultProps = {
    center: false,
    width: 100,
};

export const Link = styled.a`
    transition: all .2s;
    &:hover{
        cursor: pointer;
    }
`;

export const EmailLink = styled.a`
	display: block;
	text-align: center;
	text-decoration: underline;
	margin-bottom: 40px;
	transition: all .2s;
	font-size: 3.2rem;
    color: ${colors.textLight};
    
    @media all and (max-width: ${breakpoints.medium}){
        font-size: 2.4rem;
    }
    
    @media all and (max-width: ${breakpoints.small}){
        font-size: 1.8rem;
    }
    
	&:hover {
		text-decoration: none;
		cursor: pointer;
	}
`;

const Typography = {
    Paragraph,
    Description,
    Text,
    Title,
    BreakLine,
    EmailLink,
    Link,
};

export default Typography;
