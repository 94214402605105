import styled from 'styled-components';
import { colors } from '../../style/constants';

export const OrderedList = styled.ol`
    margin-top: 0;
	counter-reset: item;
	list-style-type: none;
	padding-inline-start: 0px;
`;
export const ListItem = styled.li`
	margin-bottom: 30px;
	position: relative;
	padding-left: 60px;
  
	&:before {
		content: counter(item) " ";
		counter-increment: item;
		background-color: ${colors.bgDark};
		font-weight: 400;
		font-size: 16px;
		color: ${colors.textLight};
		display: block;
		position: absolute;
		top: 4px;
		left: 0;
		width: 26px;
		height: 26px;
		border-radius: 26px;
		text-align: center;
		line-height: 26px;
		padding: 8px;
	}
`;

const Lists = {
    OrderedList,
    ListItem,
};

export default Lists;
