import styled from 'styled-components';
import { colors } from '../../style/constants';

export const PopupMask = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 250;
	background: ${colors.popupMask};
`;

export const Popup = styled.div`
	position: relative;
	width: 80%;
	margin: 15px auto;
	background: ${colors.bgLight};
	border-radius: 4px;
	padding: 25px;
	height: calc(100% - 30px);
	box-sizing: border-box;
	overflow-y: scroll;
`;

export const PopupCloseButton = styled.button`
	position: absolute;
	top: 8px;
	right: 8px;
	width: 35px;
	height: 35px;
	padding: 0;
	border: none;
	border-radius: 50%;
	line-height: 35px;
	text-align: center;
	background: ${colors.errorLight};
	cursor: pointer;
	outline: none;
	transition: all .2s;
	
	&:hover {
		box-shadow: inset 10px -6px 4px -8px ${colors.popupMask};
	}
`;
