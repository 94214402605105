import styled, { css } from 'styled-components';
import { colors, padding, margin } from '../../style/constants';

const successButtonStyles = css`
    display: block;
    min-width: 90px;
    max-width: 250px;
    width: fit-content;
    white-space: nowrap;
    text-align: center;
	background: ${colors.successDark};
	color: ${colors.textLight};
	padding: ${padding.button};
	border-radius: 30px;
	margin-top:  ${margin.buttonSmall};
	font-weight: 600;
    box-shadow: 0 0 4px rgba(0,0,0,0.3);
    cursor: pointer;
	
	&:hover {
		background: ${colors.successLight};
		box-shadow: 0 0 10px rgba(0,0,0,0.3);
	}
`;

export const SubscribeButton = styled.button`
    ${successButtonStyles}
    outline: none;
    border: none;
`;

export const SubscribeLink = styled.a`
    ${successButtonStyles}
    text-decoration: none;
`;

const Buttons = {
    SubscribeButton,
    SubscribeLink,
};

export default Buttons;
