import React from 'preact/compat';
import {
    Section, LeftColumn, RightColumn, ContentContainer,
} from '../../elements/composition';
import { Description, Paragraph, Title } from '../../elements/typography';
import Picture from '../../elements/Picture';

import museum from '../../../assets/img/img-museum.jpg';
import museumRetina from '../../../assets/img/img-museum@2x.jpg';
import museumMobile from '../../../assets/img/img-museum-mobile.jpg';
import museumMobileRetina from '../../../assets/img/img-museum-mobile@2x.jpg';

const AboutSection = (
) => (
    <Section id="about">
        <LeftColumn mobileWidth={40}>
	        <Picture
	            mobile={museumMobile}
	            mobileRetina={museumMobileRetina}
	            standard={museum}
	            standardRetina={museumRetina}
	            alt="Museum image"
	        />
        </LeftColumn>
        <RightColumn mobileWidth={60} mobileMargin>
            <ContentContainer>
                <Title>about us</Title>
                <Description>We are a consortium across the full spectrum of the art</Description>
                <Paragraph>
	                We are a consortium across the full spectrum of the art environment bringing together artists,
	                artist estates, art historians, curators, advisors, dealers, commercial galleries, art-tech companies,
	                non-profit organisations, museums, insurers, lawyers, valuation experts, collectors...
                </Paragraph>
                <Paragraph>
	                We have joined forces to develop a universal standard enabling to attach a unique identifier (aID) to each artwork.
                </Paragraph>
                <Paragraph>
	                We plan to leverage blockchain technologies to anchor these unique identifiers in an unalterable distributed ledger.
                </Paragraph>
	            <Paragraph>
		            We organise our activities alongside six working groups:
	            </Paragraph>
	            <ul>
		            <li>Market Intelligence</li>
		            <li>Governance</li>
		            <li>Technical</li>
		            <li>Outreach</li>
		            <li>Funding</li>
		            <li>Legal</li>
	            </ul>
                <Paragraph>
					Art Identification Standard is a not for profit initiative.
                </Paragraph>
            </ContentContainer>
        </RightColumn>
    </Section>
);

export default AboutSection;
