import { Field, Form } from 'formik';
import React from 'preact/compat';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { breakpoints, colors } from '../../style/constants';

const FormSection = styled.div`
	width: 50%;
	box-sizing: border-box;
	background: ${colors.warningLight};
	padding: 10px;
	
	@media all and (max-width:${breakpoints.small}) {
		width: 100%;
		padding: 15px;
	}
`;

const StyledPopupForm = styled(Form)`
	display: flex;
	flex-wrap: wrap;
	
	span { 
		font-size: 18px;
	}
	
	label, p {
		display: block;
		color: ${colors.textSemi};
	}
	
	label.labelCheckbox {
		color: ${colors.textDark};
	}
	
	input[type="text"], input[type="email"], input[type="number"] {
		display: block;
	    padding: 4px 6px;
	    margin: 0 0 8px 0;
	    border: 1px solid ${colors.textSemi};
	    border-radius: 4px;
	    box-sizing: border-box;
	    transition: all 0.2s;

	    @media all and (max-width:${breakpoints.small}) {
			width: 100%;
		}
	}
	
	select {
		display: block;
		margin: 0 0 8px 0;
		max-width: 100%;
	}
	
	button {
		margin-bottom: 10px;
	}
`;

const ErrMessage = styled.div`
	background: ${colors.warningDark};
	width: fit-content;
	max-width: 100%;
	text-align: center;
	padding: 2px;
`;

const SubscriptionErrorWrapper = styled.div`
    width: 100%;
	background: ${colors.warningLight};
	padding: 10px;
`;


const StyledTextarea = styled.textarea`
	width: 100%;
	resize: none;
	box-sizing: border-box;
	padding: 4px;
`;

const Textarea = ({ name }) => (
    <Field
        name={name}
        render={({ field, form }) => (
            <StyledTextarea
                rows="4"
                value={field.value}
                {...field}
            />
        )}
    />
);
Textarea.propTypes = {
    name: PropTypes.string.isRequired,
};


const NewsletterForm = styled.form`
    #mc_embed_signup_scroll div.mce_inline_error {
        background-color: ${colors.errorDark};
        max-width: 400px;
        box-sizing: border-box;
    }
`;

const basicInput = css`
    display: block;
    border-radius: 4px;
    margin: 16px 0 16px 0;
    border: 1px solid ${colors.bgSemi};
    padding: 8px 16px;
    text-align: center;
    outline: none;
    transition: all .2s;
    font-weight: 300;
    box-sizing: border-box;
    width: 100%;
    max-width: 400px;
    font-size: 1.6rem;
    
    &:focus {
        box-shadow: 0 2px 4px rgba(0,0,0,0.3);
    }`;

const InputText = styled.input.attrs({
    type: 'text',
})`
    ${basicInput}
`;
const InputEmail = styled.input.attrs({
    type: 'email',
})`
    ${basicInput}
`;

const InputCheckbox = styled.input.attrs({
    type: 'checkbox',
})`
   margin-right: 8px; 
`;

const responseStyle = css`
    display: none;
    padding: 10px;
    text-align: center;
    border-radius: 4px;
    max-width: 400px;
    box-sizing: border-box;
`;

const PositiveResponse = styled.div`
    ${responseStyle}
    border: 1px dashed ${colors.successDark};
`;

const PositiveResponseWrapper = styled(PositiveResponse)`
    display: block;
`;

const NegativeResponse = styled.div`
    ${responseStyle}
    border: 1px dashed ${colors.errorDark};
`;
const invisibleStyle = css`
    position: absolute; 
    overflow: hidden; 
    clip: rect(0 0 0 0); 
    height: 1px; 
    width: 1px; 
    margin: -1px; 
    padding: 0; 
    border: 0;
`;

const InvisibleContainer = styled.div`
    ${invisibleStyle}
`;

const InvisibleLabel = styled.label`
    ${invisibleStyle}
`;

const CheckboxList = styled.ul`
    list-style-type: none;
    padding-inline-start: 24px;
    
    li {
        margin-bottom: 8px;
        font-weight: 300;
    }
`;

const Checkbox = ({ name }) => (
    <Field
        name={name}
        render={({ field, form }) => (
            <InputCheckbox
                checked={field.value}
                {...field}
            />
        )}
    />
);
Checkbox.propTypes = {
    name: PropTypes.string.isRequired,
};

export {
    FormSection,
    StyledPopupForm,
    ErrMessage,
    SubscriptionErrorWrapper,
    Textarea,
    Checkbox,
    CheckboxList,
    InvisibleLabel,
    InvisibleContainer,
    PositiveResponseWrapper,
    PositiveResponse,
    InputCheckbox,
    InputText,
    InputEmail,
    NegativeResponse,
    NewsletterForm,
};
