import { h, Component } from 'preact';
import React from 'preact/compat';
import { withCookies } from 'react-cookie';
import OnImagesLoaded from 'react-on-images-loaded/lib/OnImagesLoaded';
import Header from './header';
import Main from './main';
import Footer from './footer';
import CookiePopup from './elements/cookiePopup';

class App extends Component {
    constructor() {
        super();
        this.state = {
        	cookieConfirmed: false,
        };
    }

    componentDidMount() {
	    const { cookies } = this.props;
	    if (cookies.get('cookiesConfirmed')) {
	    	this.setState({
			    cookieConfirmed: true,
		    });
	    }
    }

	handleCookieConfirmation = () => {
    	const { cookies } = this.props;
    	cookies.set('cookiesConfirmed', 'true');
    	this.setState({
		    cookieConfirmed: true,
	    });
	};

    hideLoader = () => {
    	const loader = document.getElementById('loader');
        loader.style.opacity = 0;
        setTimeout(() => { loader.style.display = 'none'; }, 200);
    };

    render() {
    	const { cookieConfirmed } = this.state;
	    return (
	        <OnImagesLoaded
	            onLoaded={this.hideLoader}
	        >
		        {!cookieConfirmed && (
			        <CookiePopup confirmCookie={this.handleCookieConfirmation} />
		        )}
		        <Header />
		        <Main />
		        <Footer />
	        </OnImagesLoaded>
	    );
    }
}


export default withCookies(App);
