import React from 'preact/compat';
import { Icon, AssetsContainer, Asset } from '../../elements/icons';
import { ContentContainer, Section } from '../../elements/composition';
import {
    Description, Text, Title, BreakLine,
} from '../../elements/typography';
import { width } from '../../../style/constants';
// icons import
import duplication from '../../../assets/ico/WHY/ico-duplication.png';
import duplicationRetina from '../../../assets/ico/WHY/ico-duplication@2x.png';
import incompatibility from '../../../assets/ico/WHY/ico-incompability.png';
import incompatibilityRetina from '../../../assets/ico/WHY/ico-incompability@2x.png';
import asymmetry from '../../../assets/ico/WHY/ico-asymmetry.png';
import asymmetryRetina from '../../../assets/ico/WHY/ico-asymmetry@2x.png';
import distortion from '../../../assets/ico/WHY/ico-distortion.png';
import distortionRetina from '../../../assets/ico/WHY/ico-distortion@2x.png';
import diversity from '../../../assets/ico/WHY/ico-diversity.png';
import diversityRetina from '../../../assets/ico/WHY/ico-diversity@2x.png';

const WhySection = (

) => (
    <Section id="why">
        <ContentContainer>
            <Title center>why</Title>
            <Description center maxWidth={800}>
                The multiplicity of solutions to identify artworks and describe associated provenance events,
                leads to inefficiencies and unsustainable practices, such as
            </Description>
        </ContentContainer>
        <ContentContainer maxWidth={width.maxIconSection}>
            <AssetsContainer>
                <Asset>
                    <Icon src={duplication} srcSet={`${duplicationRetina} 2x`} alt="Duplication icon" />
                    <Text>Duplication<BreakLine />of effort</Text>
                </Asset>
                <Asset>
                    <Icon src={incompatibility} srcSet={`${incompatibilityRetina} 2x`} alt="Incompatibility icon" />
                    <Text>Incompatibility<BreakLine />across systems</Text>
                </Asset>
                <Asset>
                    <Icon src={distortion} srcSet={`${distortionRetina} 2x`} alt="Distortion icon" />
                    <Text>Loss and distortion<BreakLine />of information</Text>
                </Asset>
                <Asset>
                    <Icon src={asymmetry} srcSet={`${asymmetryRetina} 2x`} alt="Asymmetry icon" />
                    <Text>Asymmetric<BreakLine />information</Text>
                </Asset>
                <Asset>
                    <Icon src={diversity} srcSet={`${diversityRetina} 2x`} alt="Diversity icon" />
                    <Text>Diversity<BreakLine />of views</Text>
                </Asset>
            </AssetsContainer>
        </ContentContainer>
    </Section>
);

export default WhySection;
