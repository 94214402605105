import React from 'preact/compat';

import { SubscribeButton } from '../../elements/buttons';
import { Paragraph } from '../../elements/typography';
import {
    InputCheckbox, InputEmail, InputText, InvisibleContainer, InvisibleLabel, NegativeResponse,
    NewsletterForm, PositiveResponse, CheckboxList,
} from '../../elements/form';

const NewsletterSubscriptionForm = () => (
    <div id="mc_embed_signup">
        <NewsletterForm
            action="https://artidstandard.us4.list-manage.com/subscribe/post?u=fb90791167f3420af17ee3ad8&amp;id=7a8aacbf7c"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
            target="_blank"
        >
            <div id="mc_embed_signup_scroll">
                <div className="mc-field-group">
                    <InvisibleLabel htmlFor="mce-FNAME"> Name </InvisibleLabel>
                    <InputText value="" name="FNAME" className="" id="mce-FNAME" placeholder="Name" />
                </div>
                <div className="mc-field-group">
                    <InvisibleLabel htmlFor="mce-MMERGE3">Surname </InvisibleLabel>
                    <InputText value="" name="MMERGE3" className="" id="mce-MMERGE3" placeholder="Surname" />
                </div>
                <div className="mc-field-group">
                    <InvisibleLabel htmlFor="mce-MMERGE2"> Organisation </InvisibleLabel>
                    <InputText value="" name="MMERGE2" className="" id="mce-MMERGE2" placeholder="Organisation" />
                </div>
                <div className="mc-field-group">
                    <InvisibleLabel htmlFor="mce-EMAIL"> Email Address </InvisibleLabel>
                    <InputEmail value="" name="EMAIL" className="required email" id="mce-EMAIL" placeholder="Enter your e-mail address*" />
                </div>

                <Paragraph>How do you wish to be involved? [tick as many boxes as you wish]</Paragraph>

                <div className="mc-field-group input-group">
                    <strong>Join AIS mailing list </strong>
                    <CheckboxList>
                        <li>
                            <InputCheckbox value="1" name="group[52515][1]" id="mce-group[52515]-52515-0" />
                            <label htmlFor="mce-group[52515]-52515-0">News</label>
                        </li>
                        <li>
                            <InputCheckbox value="2" name="group[52515][2]" id="mce-group[52515]-52515-1" />
                            <label htmlFor="mce-group[52515]-52515-1">Workshops and events</label>
                        </li>
                    </CheckboxList>
                </div>
                <div className="mc-field-group input-group">
                    <strong>Interested in becoming involved with AIS? </strong>
                    <CheckboxList>
                        <li>
                            <InputCheckbox value="4" name="group[54476][4]" id="mce-group[54476]-54476-0" />
                            <label htmlFor="mce-group[54476]-54476-0">Be part of a working group</label>
                        </li>
                        <li>
                            <InputCheckbox value="8" name="group[54476][8]" id="mce-group[54476]-54476-1" />
                            <label htmlFor="mce-group[54476]-54476-1">Research (academia, history, technical, ...)</label>
                        </li>
                        <li>
                            <InputCheckbox value="16" name="group[54476][16]" id="mce-group[54476]-54476-2" />
                            <label htmlFor="mce-group[54476]-54476-2">Promote on your channels</label>
                        </li>
                        <li>
                            <InputCheckbox value="32" name="group[54476][32]" id="mce-group[54476]-54476-3" />
                            <label htmlFor="mce-group[54476]-54476-3">Host events (workshops, panels, ...)</label>
                        </li>
                        <li>
                            <InputCheckbox value="64" name="group[54476][64]" id="mce-group[54476]-54476-4" />
                            <label htmlFor="mce-group[54476]-54476-4">Vote (subscribe first)</label>
                        </li>
                        <li>
                            <InputCheckbox value="128" name="group[54476][128]" id="mce-group[54476]-54476-5" />
                            <label htmlFor="mce-group[54476]-54476-5">Sponsor/fund</label>
                        </li>
                    </CheckboxList>
                </div>
                <div id="mce-responses" className="clear">
                    <NegativeResponse className="response" id="mce-error-response" />
                    <PositiveResponse className="response" id="mce-success-response" />
                </div>

                <InvisibleContainer aria-hidden="true">
                    <input type="text" name="b_fb90791167f3420af17ee3ad8_7a8aacbf7c" tabIndex="-1" value="" />
                </InvisibleContainer>
                <div className="clear">
                    <SubscribeButton name="subscribe" id="mc-embedded-subscribe" className="button">
                        Sign up for Newsletter
                    </SubscribeButton>
                </div>
            </div>
        </NewsletterForm>
    </div>
);


export default NewsletterSubscriptionForm;
