import React from 'preact/compat';
import PropTypes from 'prop-types';
import { ErrorMessage, Field, Formik } from 'formik';
import { SubscribeButton } from '../../elements/buttons';
import { Popup, PopupCloseButton, PopupMask } from '../../elements/formPopup';
import {
    Checkbox, FormSection, ErrMessage, StyledPopupForm, SubscriptionErrorWrapper, Textarea,
} from '../../elements/form';
import { CurrentRoles, WorkingGroups } from './helpers/constants';
import { MemberFormValidationSchema } from './helpers/validationShemes';

const MemberSubscriptionForm = ({
    handleMemberSubscription,
    closeModal,
    subscriptionError,
}) => (
    <PopupMask>
        <Popup>
            <PopupCloseButton onClick={closeModal}>X</PopupCloseButton>

            <Formik
                initialValues={{
                    firstName: '',
                    lastName: '',
                    title: '',
	                currentRole: '',
                    phoneNumber: '',
                    email: '',
                    linkedin: '',
                    memberships: '',
                    languages: '',
                    company: '',
                    jobTitle: '',
                    website: '',
                    city: '',
                    province: '',
                    postalCode: '',
                    country: '',
                    activeArea: '',
                    benefits: '',
                    values: '',
                    hours: '',

                    [WorkingGroups.market.name]: false,
                    [WorkingGroups.outreach.name]: false,
	                [WorkingGroups.legal.name]: false,
                    [WorkingGroups.funding.name]: false,
                    [WorkingGroups.technical.name]: false,
                    [WorkingGroups.governance.name]: false,
                }}
			     onSubmit={(values) => {
				     handleMemberSubscription(values);
			     }}
                validationSchema={MemberFormValidationSchema}
            >
	            {/* eslint-disable-next-line no-unused-vars */}
	            {({ errors, touched }) => (
		            <StyledPopupForm>
			            <FormSection>
				            <span>Personal</span>
				            <label>
					            Title*
					            <Field as="select" name="title">
						            <option value="Dr."> Dr. </option>
						            <option value="Mr."> Mr. </option>
						            <option value="Mrs."> Mrs. </option>
						            <option value="Ms."> Ms. </option>
						            <option value="Prof."> Prof. </option>
					            </Field>
				            </label>
				            <ErrorMessage name="title">{(msg) => <ErrMessage>{msg}</ErrMessage>}</ErrorMessage>
				            <label>
					            First Name*
					            <Field type="text" name="firstName" />
				            </label>
				            <ErrorMessage name="firstName">{(msg) => <ErrMessage>{msg}</ErrMessage>}</ErrorMessage>
				            <label>
					            Last Name*
					            <Field type="text" name="lastName" />
				            </label>
				            <ErrorMessage name="lastName">{(msg) => <ErrMessage>{msg}</ErrMessage>}</ErrorMessage>
				            <label>
					            Please select one of the following categories which best suits your current role*
					            <Field as="select" name="currentRole">
						            <option value={CurrentRoles.a.value}> {CurrentRoles.a.description} </option>
						            <option value={CurrentRoles.b.value}> {CurrentRoles.b.description} </option>
						            <option value={CurrentRoles.c.value}> {CurrentRoles.c.description} </option>
						            <option value={CurrentRoles.d.value}> {CurrentRoles.d.description} </option>
						            <option value={CurrentRoles.e.value}> {CurrentRoles.e.description} </option>
						            <option value={CurrentRoles.f.value}> {CurrentRoles.f.description} </option>
						            <option value={CurrentRoles.g.value}> {CurrentRoles.g.description} </option>
					            </Field>
				            </label>
				            <ErrorMessage name="currentRole">{(msg) => <ErrMessage>{msg}</ErrMessage>}</ErrorMessage>
			            </FormSection>
			            <FormSection>
				            <span>Address</span>
				            <label>
					            City*
					            <Field type="text" name="city" />
				            </label>
				            <ErrorMessage name="city">{(msg) => <ErrMessage>{msg}</ErrMessage>}</ErrorMessage>
				            <label>
					            State/Province*
					            <Field type="text" name="province" />
				            </label>
				            <ErrorMessage name="province">{(msg) => <ErrMessage>{msg}</ErrMessage>}</ErrorMessage>
				            <label>
					            Zip/Postal Code*
					            <Field type="text" name="postalCode" />
				            </label>
				            <ErrorMessage name="postalCode">{(msg) => <ErrMessage>{msg}</ErrMessage>}</ErrorMessage>
				            <label>
					            Country*
					            <Field type="text" name="country" />
				            </label>
				            <ErrorMessage name="country">{(msg) => <ErrMessage>{msg}</ErrMessage>}</ErrorMessage>
			            </FormSection>
			            <FormSection>
				            <span>Contact </span>
				            <label>
					            Email address*
					            <Field type="email" name="email" />
				            </label>
				            <ErrorMessage name="email">{(msg) => <ErrMessage>{msg}</ErrMessage>}</ErrorMessage>
				            <label>
					            Phone number (incl. country code)
					            <Field type="text" name="phoneNumber" />
				            </label>
				            <ErrorMessage name="phoneNumber">{(msg) => <ErrMessage>{msg}</ErrMessage>}</ErrorMessage>
				            <label>
					            LinkedIn profile
					            <Field type="text" name="linkedin" />
				            </label>
				            <ErrorMessage name="linkedin">{(msg) => <ErrMessage>{msg}</ErrMessage>}</ErrorMessage>
				            <label>
					            Website*
					            <Field type="text" name="website" />
				            </label>
				            <ErrorMessage name="website">{(msg) => <ErrMessage>{msg}</ErrMessage>}</ErrorMessage>
			            </FormSection>
			            <FormSection>
				            <span> Corporation </span>
				            <label>
					            Name of Company/Firm/Artist's Estate*
					            <Field type="text" name="company" />
				            </label>
				            <ErrorMessage name="company">{(msg) => <ErrMessage>{msg}</ErrMessage>}</ErrorMessage>
				            <label>
					            Job Title
					            <Field type="text" name="jobTitle" />
				            </label>
				            <ErrorMessage name="jobTitle">{(msg) => <ErrMessage>{msg}</ErrMessage>}</ErrorMessage>
				            <label>
					            How many hours per month do you think
					            you and/or other company representatives
					            could contribute to AIS? *
					            <Field type="number" name="hours" />
				            </label>
				            <ErrorMessage name="hours">{(msg) => <ErrMessage>{msg}</ErrMessage>}</ErrorMessage>
			            </FormSection>
			            <FormSection>
				            <label>
					            Countries/Continents where active
					            <Textarea name="activeArea" />
				            </label>
				            <ErrorMessage name="activeArea">{(msg) => <ErrMessage>{msg}</ErrMessage>}</ErrorMessage>
			            </FormSection>
			            <FormSection>
				            <label>
					            Other Professional memberships
					            <Textarea name="memberships" />
				            </label>
				            <ErrorMessage name="memberships">{(msg) => <ErrMessage>{msg}</ErrMessage>}</ErrorMessage>
			            </FormSection>
			            <FormSection>
				            <label>
					            Languages other than English
					            <Textarea name="languages" />
				            </label>
				            <ErrorMessage name="languages">{(msg) => <ErrMessage>{msg}</ErrMessage>}</ErrorMessage>
			            </FormSection>
			            <FormSection>
				            <label>
					            What value (skills, network, funding, …) do
					            you see your organisation adding to AIS? *
					            <Textarea name="values" />
				            </label>
				            <ErrorMessage name="values">{(msg) => <ErrMessage>{msg}</ErrMessage>}</ErrorMessage>
			            </FormSection>
			            <FormSection>
				            <label>
					            How could you or your organisation
					            benefit from joining the AIS consortium and
					            inserting aIDs in your ecosystem? *
					            <Textarea name="benefits" />
				            </label>
				            <ErrorMessage name="benefits">{(msg) => <ErrMessage>{msg}</ErrMessage>}</ErrorMessage>
			            </FormSection>
			            <FormSection>
				            <p>Please list which working groups your
					            organisation would like to be actively part of*
				            </p>
				            {Object.values(WorkingGroups).map((group, index) => (
					            <label
						            key={index}
					            >
						            <Checkbox name={group.name} />
						            {group.label}
					            </label>
				            ))}
				            {/* below error represents error when no one group selected */}
				            <ErrorMessage
					            name={WorkingGroups.market.name}
				            >{(msg) => <ErrMessage>{msg}</ErrMessage>}
                            </ErrorMessage>
			            </FormSection>
			            <SubscribeButton type="submit"> Become a member </SubscribeButton>
			            {subscriptionError && (
				            <SubscriptionErrorWrapper>
					            <p>
						            Something went wrong. Please try one more time later. <br />
						            If you see this error again please download below pdf file. <br />
						            Then fill document and send back to: <a href="mailto:theo@artidstandard.org"> Theo from AIS </a> <br />
					            </p>
					            <a
                                    href="https://drive.google.com/open?id=1vKwjhNK6tWMrGylKP0Hx1FXWziGsDlaF"
                                    target="_blank"
					            > AIS Membership Application Form
                                </a>
				            </SubscriptionErrorWrapper>
			            )}
		            </StyledPopupForm>
	            )}
            </Formik>
        </Popup>
    </PopupMask>
);
MemberSubscriptionForm.propTypes = {
    handleMemberSubscription: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    subscriptionError: PropTypes.bool.isRequired,
};

export default MemberSubscriptionForm;
