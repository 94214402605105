import * as Yup from 'yup';

// eslint-disable-next-line no-useless-escape
const phoneRegExpString = '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$';
const phoneRegExp = new RegExp(phoneRegExpString, 'g');

const MemberFormValidationSchema = Yup.object().shape({
    firstName: Yup.string()
        .max(20)
        .required('Please enter your first name'),
    lastName: Yup.string()
        .max(30)
        .required('Please enter your last name'),
    currentRole: Yup.string()
        .required('Please select your current role'),
    title: Yup.string()
        .required('Please select your title'),
    phoneNumber: Yup.string()
        .matches(phoneRegExp, 'Please enter correct phone number'),
    email: Yup.string()
        .email('Please enter correct email address')
        .required('Please enter email address'),
    linkedin: Yup.string()
        .url('Please enter correct URL address'),
    memberships: Yup.string()
        .max(200, 'Field is limited to 200 characters'),
    languages: Yup.string()
        .max(200, 'Fields is limited to 200 characters'),
    company: Yup.string()
        .min(2, 'Please enter correct field value')
        .max(40, 'Field is limited to 40 characters')
        .required('Please enter field value'),
    jobTitle: Yup.string()
        .max(20, 'Field is limited to 20 characters'),
    website: Yup.string()
        .url('Please enter correct URL address')
        .required('Please enter the website address'),
    city: Yup.string()
        .max(30, 'Field is limited to 30 characters')
        .required('Please enter the city'),
    province: Yup.string()
        .max(30, 'Field is limited to 30 characters')
        .required('Please enter the province/state'),
    postalCode: Yup.string()
        .max(12, 'Field is limited to 12 characters')
        .required('Please enter the Postcode/ZIP'),
    country: Yup.string()
        .max(56, 'Field is limited to 56 characters')
        .required('Please enter the Country'),
    activeArea: Yup.string()
        .max(200, 'Field is limited to 200 characters'),
    benefits: Yup.string()
        .min(10, 'Please enter at least 10 characters')
        .max(400, 'Field is limited to 100 characters')
        .required('Please enter field value'),
    values: Yup.string()
        .max(150, 'Field is limited to 150 characters')
        .required('Please enter value'),
    hours: Yup.number()
        .moreThan(-1, 'Please enter value greater or equal zero')
        .required('Please enter hours value'),
    market: Yup.bool(),
    outreach: Yup.bool(),
    legal: Yup.bool(),
    funding: Yup.bool(),
    technical: Yup.bool(),
    governance: Yup.bool(),
}).test(
    'test if any position from working groups has been selected',
    null,
    (obj) => {
        if (obj.market
			|| obj.outreach
			|| obj.legal
			|| obj.funding
			|| obj.technical
			|| obj.governance
        ) {
            return true;
        }

        // NOTE that if no one group selected error will appear with 'market' key
        return new Yup.ValidationError(
            'Please select at least one working group',
            null,
            'market',
        );
    },
);

export {
    // eslint-disable-next-line import/prefer-default-export
    MemberFormValidationSchema,
};
