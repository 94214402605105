import React from 'preact/compat';
import styled from 'styled-components';
import BannerSection from './components/BannerSection';
import WhySection from './components/WhySection';
import EngagementSection from './components/EngagementSection';
import AboutSection from './components/AboutSection';
import ManifestoSection from './components/ManifestoSection';
import InvolvedSection from './components/InvolvedSection';
import ContactSection from './components/ContactSection';
import MembersSection from './components/MembersSection';
import NewsSection from './components/NewsSection';

const MainStyled = styled.main``;

const Main = () => (
    <MainStyled>
        <BannerSection />
        <WhySection />
        <AboutSection />
        <ManifestoSection />
        <EngagementSection />
        <InvolvedSection />
        <ContactSection />
{/*        <NewsSection />*/}
        <MembersSection />
    </MainStyled>
);
export default Main;
