import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { breakpoints } from '../../style/constants';

export const AssetsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Asset = styled.div`
  width: calc( 33% - 32px );
  box-sizing: border-box;
  margin: 0 16px 24px 16px;
  text-align: center;
  
  @media all and (max-width: ${breakpoints.small}){
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 0;

    h3 {
        margin-left: 24px;
    }
  }
`;

export const LogoAsset = styled.div`
  width: 20%;
  min-width: 80px;
  padding: 24px 32px;
  box-sizing: border-box;
  
  @media all and (max-width: ${breakpoints.large}){
    padding: 12px 16px;
  }
  
  @media all and (max-width: ${breakpoints.medium}){
    width: 33%;
  }
  
  @media all and (max-width: ${breakpoints.small}){
    padding: 6px 8px;
  }
`;

export const Icon = styled.img`
  @media all and (max-width: ${breakpoints.medium}){
     height: 48px;
  }
  
  @media all and (max-width: ${breakpoints.small}){
     height: 32px;
  }
`;

export const opacityStyles = css`
    -webkit-transform: translateZ(0);
    opacity: 0.7;
    transition: all .2s;
    
    &:hover {
        opacity: 1;
    }
`;

export const Image = styled.img`
    display: block;
	width: 100%;
	min-width: initial;
	max-width: 100%;
	height: auto;
	${(props) => (props.opacityChange ? opacityStyles : '')}
`;
Image.propTypes = {
    opacityChange: PropTypes.bool,
};
Image.defaultProps = {
    opacityChange: false,
};

const Icons = {
    AssetsContainer,
    Asset,
    Icon,
    Image,
    LogoAsset,
};

export default Icons;
