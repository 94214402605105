import React from 'preact/compat';
import { ContentContainer, Section } from '../../elements/composition';
import { Title, Description } from '../../elements/typography';

const NewsSection = () => (
    <Section id="news">
        <ContentContainer>
            <Title center>News</Title>
	        <Description center>Below you will find links related to the project</Description>
        </ContentContainer>
    </Section>
);

export default NewsSection;
