import React, { useState, useEffect } from 'preact/compat';
import { withCookies } from 'react-cookie';
import {
    Section, ContentContainer, RightColumn, LeftColumn,
} from '../../elements/composition';
import { Description, Title } from '../../elements/typography';
import NewsletterSubscriptionForm from './NewsletterSubscriptionForm';
import { Image } from '../../elements/icons';

import multicolored from '../../../assets/img/img-multicolored.jpg';
import multicoloredRetina from '../../../assets/img/img-multicolored@2x.jpg';
import MemberSubscriptionForm from './MemberSubscriptionForm';
import { SubscribeButton } from '../../elements/buttons';
import { CurrentRoles, WorkingGroups } from './helpers/constants';
import { PositiveResponseWrapper } from '../../elements/form';


const InvolvedSection = (props) => {
    const [userSendMembershipForm, setUserSendMembershipForm] = useState(false);
    const [memberFormVisible, setMemberFormVisible] = useState(false);
    const [formSendingError, setFormSendingError] = useState(false);

    const openCloseMemberForm = () => {
        setMemberFormVisible((prevState) => !prevState);
    };

    const prepareFormData = (form) => {
    	let groups = '';
    	const workingGroupsNames = Object.values(WorkingGroups).map((group) => (group.name));

    	workingGroupsNames.forEach((groupName) => {
    		if (form[groupName]) {
    			// NOTE here we use that name of WorkingGroups key is the same as it's name value
			    groups = groups.concat(WorkingGroups[groupName].label, ', ');
		    }
	    });

	    // NOTE here we use that name of CurrentRole key is the same as it's value
    	const currentRole = CurrentRoles[form.currentRole].description;

    	const dateFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    	const date = new Date();
    	const applicationDate = date.toLocaleDateString('en-US', dateFormatOptions);

    	// NOTE it might be simplified but for readability stay as below
	    return {
		    applicationDate,
		    email: form.email,
		    title: form.title,
		    firstName: form.firstName,
		    lastName: form.lastName,
		    company: form.company,
		    jobTitle: form.jobTitle,
		    currentRole,
		    languages: form.languages,
		    phoneNumber: form.phoneNumber,
		    linkedin: form.linkedin,
		    memberships: form.memberships,
		    website: form.website,
		    city: form.city,
		    province: form.province,
		    postalCode: form.postalCode,
		    country: form.country,
		    activeArea: form.activeArea,
		    benefits: form.benefits,
		    groups,
		    hours: form.hours,
	    };
    };

    // emiljs is present in the template.html file as js script
    const sendFeedback = (templateId, variables) => window.emailjs.send(
        'gmail', templateId,
        variables,
    );

    const handleMemberSubscription = (values) => {
        // here we send email using email.js
	    // templateId comes from emailjs account and it is available here https://dashboard.emailjs.com/
	    const templateId = 'ais_member';
	    const variables = prepareFormData(values);

	    sendFeedback(templateId, variables).then((res) => {
	    	// NOTE here is all backend response
	    	if (res.status === 200 && res.text === 'OK') {
	    		props.cookies.set('memberSubscription', {
	    			memberName: variables.firstName,
				    date: variables.applicationDate,
	    		});
			    openCloseMemberForm();
			    setFormSendingError(false);
		    } else {
			    console.warn(res);
			    setFormSendingError(true);
		    }
	    }).catch((err) => {
	    	console.error(err);
	    	setFormSendingError(true);
	    });
    };

    useEffect(() => {
    	if (props.cookies.get('memberSubscription')) {
		    setUserSendMembershipForm(true);
	    } else {
		    setUserSendMembershipForm(false);
	    }
    });

    return (
        <Section id="involved">
            <LeftColumn mobileWidth={60}>
		        <ContentContainer>
			        <Title>become involved</Title>
			        <Description>Interested by the Art Identification Standard consortium?</Description>
                    <NewsletterSubscriptionForm />
			        {userSendMembershipForm && (
			        	<PositiveResponseWrapper>
					        {`Thank you ${props.cookies.get('memberSubscription').memberName} for submitting your membership application.
					        We will review and revert to you within one month.
					        Date of dispatch: ${props.cookies.get('memberSubscription').date}`}
			        	</PositiveResponseWrapper>
			        )}
			        <SubscribeButton onClick={openCloseMemberForm}>
				        Become a member
			        </SubscribeButton>
			        {memberFormVisible && (
				        <MemberSubscriptionForm
					        handleMemberSubscription={handleMemberSubscription}
					        closeModal={openCloseMemberForm}
					        subscriptionError={formSendingError}
				        />
			        )}
                </ContentContainer>
            </LeftColumn>
            <RightColumn mobileWidth={40}>
                <Image src={multicolored} srcSet={`${multicoloredRetina} 2x`} />
            </RightColumn>
        </Section>
    );
};

export default withCookies(InvolvedSection);
