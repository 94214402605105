import React from 'preact/compat';
import {
    Section, LeftColumn, RightColumn, ContentContainer,
} from '../../elements/composition';
import { Description, Title } from '../../elements/typography';
import Picture from '../../elements/Picture';
import { ListItem, OrderedList } from '../../elements/lists';

import sculpture from '../../../assets/img/img-sculpture.jpg';
import sculptureRetina from '../../../assets/img/img-sculpture@2x.jpg';
import sculptureMobile from '../../../assets/img/img-sculpture-mobile.jpg';
import sculptureMobileRetina from '../../../assets/img/img-sculptura-mobile@2x.jpg';


const ManifestoSection = (
) => (
    <Section id="manifesto" reverseOrderMobile>
        <LeftColumn mobileWidth={60} mobileMargin>
            <ContentContainer>
                <Title>our</Title>
                <Description>Manifesto</Description>
                <OrderedList>
                    <ListItem>
                        Identification and provenance standards support a sustainable and fairer art ecosystem, ultimately benefiting its stakeholders
                    </ListItem>
                    <ListItem>
                        Unique object identification (artmetrics) is the cornerstone to provenance
                    </ListItem>
                    <ListItem>
                        Artmetrics and provenance don&apos;t generate money, there are instead tools to enhance business models and operating systems
                    </ListItem>
                    <ListItem>
                        A common language across the art sector to uniquely identify an artwork and capture its lifecycle improves the fluidity of exchanges (trust, efficiency, velocity, ...)
                    </ListItem>
                    <ListItem>
                        Artists need to be empowered by having control on how their data is used
                    </ListItem>
                </OrderedList>
            </ContentContainer>

        </LeftColumn>
        <RightColumn mobileWidth={40}>
            <Picture
                mobile={sculptureMobile}
                mobileRetina={sculptureMobileRetina}
                standard={sculpture}
                standardRetina={sculptureRetina}
                alt="Sculpture image"
            />
        </RightColumn>
    </Section>
);

export default ManifestoSection;
