import React from 'preact/compat';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { SubscribeButton } from './buttons';
import { colors } from '../../style/constants';

const PopupMask = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 250;
	color: ${colors.textLight}
`;

const PopupContainer = styled.div`
	background-color: ${colors.imageMask};
	padding: 20px 20%;
`;


const CookiePopup = ({
    confirmCookie,
}) => (
    <PopupMask>
        <PopupContainer>
            <h3>We use cookies</h3>
	        <p>
		        We use cookies and other tracking technologies to improve your browsing experience on our website,
		        to analyze our website traffic, and to understand where our visitors are coming from.
		        By browsing our website, you consent to our use of cookies and other tracking technologies.
	        </p>
		    <SubscribeButton onClick={confirmCookie}>I agree</SubscribeButton>
        </PopupContainer>
    </PopupMask>
);

CookiePopup.propTypes = {
    confirmCookie: PropTypes.func.isRequired,
};


export default CookiePopup;
