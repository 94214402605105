import React from 'preact/compat';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { breakpoints, colors } from '../../style/constants';
import { ContentContainer } from '../elements/composition';

import logo from '../../assets/logo-AIS@x.png';
import logoRetina from '../../assets/logo-AIS@2x.png';


const HeaderStyled = styled.header`
	height: 80px;
	width: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 200;
`;

const Content = styled.div`
    display: flex;
	justify-content: space-between;
	align-items: center;
	height: inherit;
`;


const LogoStyled = styled.img`
	display: block;
	width: auto;
	height: 35px;
	
	@media all and (max-width: ${breakpoints.medium}) {
        height: 25px;
    }
`;

const NavStyled = styled.nav`
	display: flex;
	justify-content: center;
	align-items: center;


	@media all and (max-width: ${breakpoints.small}) {
		display: none;
	}
`;

const UlStyled = styled.ul`
	list-style-type: none;
	display: block;
	text-align: right;
`;

const LiStyled = styled.li`
	margin: 0 0 0 30px;
	text-align: center;
	vertical-align: middle;
	display: inline-block;

	@media all and (max-width: ${breakpoints.large}) {
		display: ${(props) => (props.mobileHidden ? 'none' : 'inline-block')};
	}
	@media all and (max-width: ${breakpoints.medium}) {
        margin-left: 18px;
    }
`;
LiStyled.propTypes = {
    mobileHidden: PropTypes.bool,
};
LiStyled.defaultProps = {
    mobileHidden: false,
};

const LinkStyled = styled.a`
    white-space: nowrap;
    color: ${colors.textLight};
    text-decoration: none;
    opacity: 0.8;
    transition: all .2s;
   
    &:hover {
        opacity: 1;
        cursor: pointer;
    }
`;

const Header = () => (
    <HeaderStyled>
        <ContentContainer>
            <Content>
                <LogoStyled src={logo} srcSet={`${logoRetina} 2x`} alt="Logotype of Art Identification Standard" />
                <NavStyled>
                    <UlStyled>
                        <LiStyled mobileHidden><LinkStyled href="#why">Why</LinkStyled></LiStyled>
                        <LiStyled><LinkStyled href="#about">About Us</LinkStyled></LiStyled>
                        <LiStyled><LinkStyled href="#manifesto">Manifesto</LinkStyled></LiStyled>
                        <LiStyled mobileHidden><LinkStyled href="#engagement">Engagement</LinkStyled></LiStyled>
                        <LiStyled><LinkStyled href="#involved">Involved</LinkStyled></LiStyled>
                        <LiStyled><LinkStyled href="#contact">Contact</LinkStyled></LiStyled>
{/*                        <LiStyled mobileHidden><LinkStyled href="#news">News</LinkStyled></LiStyled>*/}
                        <LiStyled mobileHidden><LinkStyled href="#members">Members</LinkStyled></LiStyled>
                    </UlStyled>
                </NavStyled>
            </Content>
        </ContentContainer>
    </HeaderStyled>
);

export default Header;
