import React from 'preact';
import PropTypes from 'prop-types';
import { Image } from './icons';


const Picture = ({
    mobile,
    mobileRetina,
    standard,
    standardRetina,
    alt,
}) => (
    <picture>
        <source media="(max-width: 899px)" srcSet={`${mobile} 1x, ${mobileRetina} 2x`} />
        <source media="(min-width: 900px)" srcSet={`${standard} 1x, ${standardRetina} 2x`} />
        <Image src={standard} alt={alt} />
    </picture>
);

Picture.propTypes = {
    mobile: PropTypes.string.isRequired,
    mobileRetina: PropTypes.string.isRequired,
    standard: PropTypes.string.isRequired,
    standardRetina: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
};

export default Picture;
