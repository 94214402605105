export const breakpoints = {
    small: '599px',
    medium: '899px',
    large: '1199px',
    huge: '1799px',
};

export const colors = {
    textLight: '#fff',
    textSemi: '#90909d',
    textDark: '#000',
    imageMask: 'rgba(0, 0, 0, 0.7)',
    popupMask: '#3c383896',
    successDark: '#00BD89',
    successLight: '#0CAA7E',
    errorDark: '#B00020',
    errorLight: '#f83333b5',
    warningLight: '#fff2e3ba',
    warningDark: '#ffff00de',
    bgLight: '#fff',
    bgSemi: '#f2f2f2',
    bgDark: '#18181c',
};

export const padding = {
    button: '16px 32px',
    sectionSmall: '24px 0',
    sectionMedium: '36px 0',
    sectionLarge: '80px 0',
};
export const margin = {
    buttonSmall: '24px',
    buttonMedium: '48px',
    containerSmall: '0 24px',
    containerMedium: '0 36px',
    containerLarge: '0 80px',
};

export const width = {
    maxIconSection: '1000px',
};

export const border = {
    input: '#aaa',
};

const theme = {
    breakpoints,
    colors,
    padding,
    margin,
    width,
    border,
};

export default theme;
