const CurrentRoles = {
    a: {
        description: 'Artist, Artist Studio Team Member',
        value: 'a',
    },
    b: {
        description: 'Author, Researcher, Academic',
        value: 'b',
    },
    c: {
        description: 'Gallery, Dealer, Auction House',
        value: 'c',
    },
    d: {
        description: 'Public Museum, Private Museum, Private Foundation',
        value: 'd',
    },
    e: {
        description: 'ArtTech, Lawyer, Art Service Provider',
        value: 'e',
    },
    f: {
        description: 'Publishing House, Media',
        value: 'f',
    },
    g: {
        description: 'Other',
        value: 'g',
    },
};
Object.freeze(CurrentRoles);


const WorkingGroups = {
    market: {
        label: 'Market Intelligence',
        name: 'market',
    },
    outreach: {
        label: 'Outreach',
        name: 'outreach',
    },
    legal: {
        label: 'Legal',
        name: 'legal',
    },
    funding: {
        label: 'Funding',
        name: 'funding',
    },
    technical: {
        label: 'Technical',
        name: 'technical',
    },
    governance: {
        label: 'Governance',
        name: 'governance',
    },
};
Object.freeze(WorkingGroups);

export {
    CurrentRoles,
    WorkingGroups,
};
