import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import {
    breakpoints, colors, margin, padding,
} from '../../style/constants';

const darkSectionStyles = css`
    color: ${colors.textLight};
    background: ${colors.bgDark};
`;

const lightSectionStyles = css`
    color: ${colors.textDark};
    background: ${colors.bgLight};
`;

const successBackgroundStyles = css`
    background: ${colors.successDark};
`;

const reverseItemsOrderStyles = css`
    display: flex;
    flex-direction: column-reverse;
`;

export const Section = styled.section`
	padding: ${padding.sectionLarge};
    position: ${(props) => props.position};
    ${(props) => (props.dark ? darkSectionStyles : lightSectionStyles)}
    ${(props) => (props.successBg ? successBackgroundStyles : '')}
    
    @media all and (max-width: ${breakpoints.medium}){
        padding: ${padding.sectionMedium};
        ${(props) => (props.reverseOrderMobile ? reverseItemsOrderStyles : null)}
    }
    
    @media all and (max-width: ${breakpoints.small}){
        padding: ${padding.sectionSmall};
        ${(props) => (props.noPaddingBottomMobile ? 'padding-bottom: 0px;' : null)};

    }
`;
Section.propTypes = {
    dark: PropTypes.bool,
    position: PropTypes.oneOf(['static', 'relative', 'fixed', 'absolute', 'sticky']),
    successBg: PropTypes.bool,
    noPaddingBottomMobile: PropTypes.bool,
    reverseOrderMobile: PropTypes.bool,
};
Section.defaultProps = {
    dark: false,
    position: 'static',
    successBg: false,
    noPaddingBottomMobile: false,
    reverseOrderMobile: false,
};

const maxWidthRestriction = css`
    max-width: ${(props) => props.maxWidth};
    margin: 0 auto;
`;

export const ContentContainer = styled.div`
    height: inherit;
    margin: ${margin.containerLarge};
    ${(props) => (props.maxWidth ? maxWidthRestriction : '')}
    
    @media all and (max-width: ${breakpoints.medium}){
        margin: ${margin.containerMedium};
    }
    
    @media all and (max-width: ${breakpoints.small}){
        margin: ${margin.containerSmall};
    }
    
    margin-bottom: ${(props) => (props.bottomMargin ? '80px' : 0)};
`;
ContentContainer.propTypes = {
    bottomMargin: PropTypes.bool,
    maxWidth: PropTypes.string,
};
ContentContainer.defaultProps = {
    bottomMargin: false,
    maxWidth: '',
};

export const floatingStyle = css`
	display: inline-block;
    display: -moz-inline-box;
    -moz-box-orient: vertical;
    vertical-align: top;
`;

export const LeftColumn = styled.div`
	${floatingStyle}
	width: ${(props) => props.standardWidth}%;
	
	@media all and (max-width: ${breakpoints.large}){
        width: ${(props) => props.mobileWidth}%;
    }
	
	@media all and (max-width: ${breakpoints.medium}){
        ${(props) => (props.hiddenLessThanMedium ? 'display: none;' : 'width: 100%;')}
        margin-top: ${(props) => (props.mobileMargin ? '24px' : '')};
    }
`;
LeftColumn.propTypes = {
    standardWidth: PropTypes.number,
    mobileWidth: PropTypes.number,
    mobileMargin: PropTypes.bool,
    hiddenLessThanMedium: PropTypes.bool,
};
LeftColumn.defaultProps = {
    standardWidth: 50,
    mobileWidth: 50,
    mobileMargin: false,
    hiddenLessThanMedium: false,
};

export const RightColumn = styled.div`
    ${floatingStyle}
    width: ${(props) => props.standardWidth}%;
    
    @media all and (max-width: ${breakpoints.large}){
        width: ${(props) => props.mobileWidth}%;
    }
    
    @media all and (max-width: ${breakpoints.medium}){
        ${(props) => (props.hiddenLessThanMedium ? 'display: none;' : 'width: 100%;')}
        margin-top: ${(props) => (props.mobileMargin ? '24px' : '')};
    }
`;
RightColumn.propTypes = {
    standardWidth: PropTypes.number,
    mobileWidth: PropTypes.number,
    mobileMargin: PropTypes.bool,
    hiddenLessThanMedium: PropTypes.bool,
};
RightColumn.defaultProps = {
    standardWidth: 50,
    mobileWidth: 50,
    mobileMargin: false,
    hiddenLessThanMedium: false,
};


const Composition = {
    Section,
    ContentContainer,
    LeftColumn,
    RightColumn,
};

export default Composition;
